import { Link } from "gatsby";
import * as React from "react";

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <h1>Error 404 - Página no encontrada</h1>
      <Link to="/">Ir a la página principal.</Link>
    </div>
  );
};

export default NotFoundPage;
